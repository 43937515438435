import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/use-siteMetadata';
import Layout from '../components/layout';
import { CardProducts } from './common';
import Pagination from '../components/pagination';
import ProductListItem from '../components/product-list-item';
import mapProduct from '../common/map-product';
import { getCanonicalUrl } from '../common/utils';
import BRANDS from '../../data/brands.json';

function getDescription(brand) {
  return `Buy ${brand} products online in Pakistan. Order ${brand} products online in Karachi, Lahore, Islamabad & all across Pakistan. Cash on delivery.`;
}

export const query = graphql`
  query($key: String!, $limit: Int!, $skip: Int!) {
    allMdx(
      filter: {frontmatter: {brand: {eq: $key}}}
      sort: {fields: frontmatter___title}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          discontinued
          slug
          title
          description
          categories
          price
          images
        }
      }
    }
  }
`;

export default ({
  data: { allMdx: { nodes: products } },
  pageContext: { key, currentPage, totalPages }
}) => {
  const brand = BRANDS[key];
  const { siteUrl } = useSiteMetadata();

  function getCanonicalSlug(pageNo) {
    return `brand/${key}` + (pageNo > 1 ? `/${pageNo}` : '');
  }

  return (
    <Layout
      title={brand.title + ' Pakistan | PasteWax'}
      description={getDescription(brand.title)}
      slug={getCanonicalSlug(currentPage)}
    >
      <Helmet>
        {currentPage < totalPages && <link rel="next" href={getCanonicalUrl(siteUrl, getCanonicalSlug(currentPage + 1))} />}
        {currentPage > 1 && <link rel="prev" href={getCanonicalUrl(siteUrl, getCanonicalSlug(currentPage - 1))} />}
      </Helmet>
      <h2>Buy {brand.title} products in Pakistan</h2>
      {currentPage === 1 && <p>{brand.description}</p>}
      <CardProducts>
        {products.map(p => {
          const product = mapProduct(p);
          return <ProductListItem key={product.slug} product={product}></ProductListItem>;
        })}
      </CardProducts>

      <Pagination type="brand" urlKey={key} currentPage={currentPage} totalPages={totalPages}></Pagination>
    </Layout>
  );
};
